import React, { createContext, useReducer } from "react";
import dynamic from "next/dynamic";
import dialogReducer from "reducers/dialogReducer";

const Dialog = dynamic(() => import("components/Dialog"), {
  ssr: false,
});

export const DialogContext = createContext({});

const DialogProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dialogReducer, {
    title: null,
    body: null,
    opened: false,
  });

  return (
    <DialogContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
      <Dialog />
    </DialogContext.Provider>
  );
};

export default DialogProvider;
