
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React from "react";
import { useRouter } from "next/router";
import ScrollProvider from "contexts/ScrollContext";
import DialogProvider from "contexts/DialogContext";
import CookieThough from "components/CookieThough";
import "styles/globals.css";

const __Page_Next_Translate__ = function App({ Component, pageProps }) {
  const router = useRouter();

  return (
    <ScrollProvider>
      <DialogProvider>
        <CookieThough />
        <Component key={router.asPath} {...pageProps} />
      </DialogProvider>
    </ScrollProvider>
  );
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  