import React, { useEffect } from "react";
import Script from "next/script";
import useTranslation from "next-translate/useTranslation";
import { init, getPreferences, onPreferencesChanged } from "cookie-though";
import config from "cookie-though.json";

export default function CookieThough() {
  const { lang } = useTranslation();

  useEffect(() => {
    const handlePreferences = ({ cookieOptions }) => {
      const consentSettings = {
        ad_storage: "denied",
        analytics_storage: "denied",
      };

      if (typeof window === "undefined") {
        return;
      }

      cookieOptions.forEach(({ id, isEnabled }) => {
        switch (true) {
          case process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID &&
            id === "google-analytics":
            if (isEnabled) {
              consentSettings.analytics_storage = "granted";
              window.gtag("send", "pageview");
            }
            window.gtag("consent", "update", consentSettings);
            break;
          case process.env.NEXT_PUBLIC_CLARITY_ID && id === "clarity":
            window.clarity("consent", isEnabled);
            break;
        }

        window.gtag("consent", "update", consentSettings);
      });
    };

    init(config[lang]);
    handlePreferences(getPreferences());
    onPreferencesChanged(handlePreferences);
  }, [lang, init, getPreferences, onPreferencesChanged]);

  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
        <>
          <Script
            id="gtag"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
            lazyOnload
          />
          <Script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){ dataLayer.push(arguments); }
                gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
                gtag('js', new Date());
                gtag("consent", "default", {
                  ad_storage: "denied",
                  analytics_storage: "denied"
                });
              `,
            }}
            lazyOnload
          />
        </>
      )}
      {process.env.NEXT_PUBLIC_CLARITY_ID && (
        <>
          <Script
            id="clarity-q"
            dangerouslySetInnerHTML={{
              __html: `window.clarity = window.clarity || function() { (window.clarity.q = window.clarity.q || []).push(arguments)};`,
            }}
            lazyOnload
          />
          <Script
            id="clarity"
            src={`https://www.clarity.ms/tag/${process.env.NEXT_PUBLIC_CLARITY_ID}`}
            lazyOnload
          />
        </>
      )}
    </>
  );
}
