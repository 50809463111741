export const ACTIONS = {
  OPEN: "open",
  CLOSE: "close",
  RESET: "reset",
};

export const STATUS = {
  OPENED: "opened",
  CLOSING: "closing",
  CLOSED: "closed",
};

export default function dialogReducer(state, action) {
  switch (action.type) {
    case ACTIONS.OPEN:
      return {
        ...state,
        title: action.payload.title,
        body: action.payload.body,
        status: STATUS.OPENED,
      };
    case ACTIONS.CLOSE:
      return {
        ...state,
        status: STATUS.CLOSING,
      };
    case ACTIONS.RESET:
      return {
        ...state,
        title: null,
        body: null,
        status: STATUS.CLOSED,
      };
    default:
      return state;
  }
}
